<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Información de la Licencia')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
						<DataTable :value="licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Licencia')">
								<template #body="slotProps">
									{{slotProps.data.categoria}}
								</template>
							</Column>
							<Column :header="$t('Número ID')">
								<template #body="slotProps">
									{{slotProps.data.numero}}
									<Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
								</template>
							</Column>
							<Column :header="$t('Nombre Completo')">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column :header="$t('Fecha de emisión')">
								<template #body="slotProps">
									{{convfech(slotProps.data.emision)}}
								</template>
							</Column>
							<Column :header="$t('Fecha de caducidad')">
								<template #body="slotProps">
									{{convfech(slotProps.data.caducidad)}}
								</template>
							</Column>
							<Column :header="$t('País de emisión')">
								<template #body="slotProps" v-if="i18n.locale() == 'es'">
									{{slotProps.data.pais.nombre}}
								</template>
								<template #body="slotProps" v-else>
									{{slotProps.data.pais.name}}
								</template>
							</Column>
							<Column :header="$t('Imagen')">
								<template #body="slotProps">
									<img style="width: 30px;" :src="url.upload+slotProps.data.img1" v-if="slotProps.data.verif"/>
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteLicencia(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
                        <small v-show="validationErrors.licencia && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4 p-md-2" v-if="!LicenciaDialog">
						<Button :disabled ="$store.state.loading" :label="$t('Agregar Licencia')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Licencia()" />
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :disabled ="$store.state.loading" :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
        <Dialog v-model:visible="LicenciaDialog" :style="{width: size}" :header="$t('Agregar Licencia')" :modal="true" :maximizable="true"> 
            <br>
            <div class="confirmation-content">
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-6">
						<label for="name">{{$t('Permiso de Conducir')}}</label>
						<MultiSelect v-model="licencia.categoria" :options="categorias" optionLabel="nombre" placeholder="...?" :filter="true" class="multiselect-custom">
							<template #value="slotProps">
								<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
									<img style="width: 20px;" :alt="slotProps.value.id" :src="'images/' + option.code + '.png'"/>
									<div>{{option.nombre}}</div>
								</div>
								<template v-if="!slotProps.value || slotProps.value.length === 0">
									{{$t('Licencia')}}
								</template>
							</template>
							<template #option="slotProps">
								<div class="country-item">
									<img style="width: 50px;" :alt="slotProps.option.id" :src="'images/' + slotProps.option.code + '.png'" />
									<div>{{slotProps.option.nombre}}</div>
								</div>
							</template>
						</MultiSelect>
                        <small v-show="validationErrors.categoria" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-6">
						<label>{{$t('Número ID')}}</label>
						<InputText v-model="licencia.numero" type="text"/>
                        <small v-show="validationErrors.numero" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12">
						<label>{{$t('Nombre Completo')}}</label>
						<InputText v-model="licencia.nombre" type="text"/>
                        <small v-show="validationErrors.nombre" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4">
						<label>{{$t('País de emisión')}}</label>
						<Dropdown v-model="licencia.pais" :options="paises" :optionLabel="$t('nombre')" :filter="true" :placeholder="$t('Seleccione...')">
							<template #value="slotProps">
								<div class="p-dropdown-car-value" v-if="slotProps.value">
									<span v-if="i18n.locale() == 'es'"> {{slotProps.value.nombre}}</span>
									<span v-if="i18n.locale() == 'en'"> {{slotProps.value.name}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
							<template #option="slotProps">
								<div class="p-dropdown-car-option">
									<span v-if="i18n.locale() == 'es'"> {{slotProps.option.nombre}}</span>
									<span v-if="i18n.locale() == 'en'"> {{slotProps.option.name}}</span>
								</div>
							</template>
						</Dropdown>
                        <small v-show="validationErrors.pais" class="p-error">{{$t('required')}}</small>
					</div>
                    <div class="p-field p-col-4">
                    <label>{{$t('Fecha de emisión')}}</label>
                        <InputMask v-model="licencia.emision" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        <small v-show="validationErrors.emision" class="p-error">{{$t('required')}}</small>
                    </div>
                    <div class="p-field p-col-4">
                    <label>{{$t('Fecha de caducidad')}}</label>
                        <InputMask v-model="licencia.caducidad" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        <small v-show="validationErrors.caducidad" class="p-error">{{$t('required')}}</small>
						<small class="p-error" v-if="i18n.locale() == 'es' && licencia.caducidad<=this.$store.state.fecha">La licencia de conducir debe ser válida. Si necesita más ayuda, llame al {{url.tlf}}</small>
						<small class="p-error" v-if="i18n.locale() == 'en' && licencia.caducidad<=this.$store.state.fecha">The driver license must be valid. If you need further assistance, please call at {{url.tlf}}</small>
                    </div>
					<div class="p-field p-col-6">
						<label>{{$t('Conductor habitual')}}</label>
                        <Checkbox v-model="licencia.habitual" :binary="true"/>
					</div>
					<div class="p-field p-col-6">
						<label>{{$t('Anverso')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="licencia.img1" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(1,licencia.img1)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.foto1" class="p-error">{{$t('required')}}</small>
						<img style="width: 60px;" :src="url.upload+licencia.img1" v-if="foto1"/>
					</div>
					<div class="p-field p-col--12">
						<label for="state">{{$t('¿Le han revocado la licencia?')}}</label>
                        <Checkbox v-model="licencia.revocado" :binary="true" />
					</div>
                    <div class="p-field p-col-12" v-if="LicenciaDialog && licencia.revocado">
                    <label>{{$t('indique los detalles')}}</label>
						<InputText v-model="licencia.detalle"  type="text"/>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button :disabled ="$store.state.loading" :label="$t('Agregar Licencia')" icon="pi pi-plus-circle" class="p-button-secondary" @click="AgregarLicencia" />
                <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="LicenciaDialog = false"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Cotizacions','Vehiculo');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			foto1: false,
			foto2: false,
			submitted: false,
            validationErrors: {},
			paises: null,
			afirmacion: [
				{nombre: 'Sí', name: 'Yes', code: true},
				{nombre: 'No', name: 'No', code: false},
			],
			LicenciaDialog: false,
			categorias: [
                {nombre: 'A', code: 'A'},
                {nombre: 'B', code: 'B'},
                {nombre: 'C', code: 'C'},
                {nombre: 'D', code: 'D'},
                {nombre: 'E', code: 'E'},
			],
			licencias: [],
			licencia: {
				numero: null,
				nombre: null,
				apellido: null,
				categoria: null,
				emision: null,
				caducidad: null,
				pais: null,
				habitual: true,
				revocado: false,
				detalle: null,				
				img1: null,			
				img2: null,			
				verif: false				
			},
            size: '40vw',
			url: null,
			contador: 0,
			prueba: null
        }
    },
	created() {
		this.contador = 0;
		this.url = url;
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
		Consulta.Ini().then(result => {
			this.paises = result.consult.paises;
		});
	},
    methods: {
        Licencia() {
			if(this.formData.datos.tipocliente.code == 1){
				this.licencia.nombre = this.formData.datos.nombre;
				this.licencia.numero = this.formData.datos.code;
			}
			this.LicenciaDialog = true;
			const tokgen = new Token(256);
			this.licencia.img1 = tokgen.generate()+'1';
			this.licencia.img2 = tokgen.generate()+'2';
		},
		myUploader(caso,img) {
			Consulta.Procesar('RevFoto',{
				foto: img,
			}).then(response => {
				if(response.result){
					if(caso==1){this.foto1 = response.result;this.licencia.img1 = response.result;} 
					if(caso==2){this.foto2 = response.result;this.licencia.img2 = response.result;} 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
			});
		},
		AgregarLicencia() {
            if (this.validateForm2() && this.foto1) {
				this.licencia.verif = true;
				var precat = null;				
				this.licencia.categoria.forEach(element => {
					if (precat == null){
						precat = element.code;
					} else {
						precat = precat+', '+element.code	;
					}
				});
				this.licencia.categoria = precat;		
				this.licencias.push(this.licencia);
				this.LicenciaDialog = false;
				this.licencia = {
					numero: null,
					nombre: null,
					apellido: null,
					categoria: null,
					emision: null,
					caducidad: null,
					pais: null,
					habitual: true,
					revocado: false,
					detalle: null,				
					img1: null,			
					img2: null,			
					verif: false				
				};
				this.foto1 = false;
				this.foto2 = false;
            }
		},
		DeleteLicencia(selec) {
			this.licencias = this.licencias.filter(val => val !== selec);
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {licencias: this.licencias}, pageIndex: 2});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 2});
        },
        validateForm() {
            if (this.licencias.length==0)
               this.validationErrors['licencia'] = true;
            else
               delete this.validationErrors['licencia'];
            return !Object.keys(this.validationErrors).length;
        },
        validateForm2() {
            const emision = Consulta.fdate(this.licencia.emision);
            const caducidad = Consulta.fdate(this.licencia.caducidad);
            if (!this.licencia.categoria)
               this.validationErrors['categoria'] = true;
            else
               delete this.validationErrors['categoria'];
            if (!this.licencia.numero)
               this.validationErrors['numero'] = true;
            else
               delete this.validationErrors['numero'];
            if (!this.licencia.nombre)
               this.validationErrors['nombre'] = true;
            else
               delete this.validationErrors['nombre'];
            if (this.licencia.pais==null)
               this.validationErrors['pais'] = true;
            else
               delete this.validationErrors['pais'];
            if (this.licencia.emision.length!=10)
               this.validationErrors['emision'] = true;
            else
               delete this.validationErrors['emision'];
            if (this.licencia.caducidad.length!=10)
               this.validationErrors['caducidad'] = true;
            else
               delete this.validationErrors['caducidad'];
            if (caducidad<=emision)
               this.validationErrors['caducidad'] = true;
            else
               delete this.validationErrors['caducidad'];
            if (!this.foto1)
               this.validationErrors['foto1'] = true;
            else
               delete this.validationErrors['foto1'];
            return !Object.keys(this.validationErrors).length;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
    }
}
</script>